* {
  box-sizing: border-box;
}

body {
  background-color: var(--background);
  color: var(--text);
  font-family: Muli, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.modal-enter > * {
  opacity: 0;
  transform: translateY(100px) scale(0.9);
}
.modal-enter-active > * {
  opacity: 1;
  transform: translateY(0) scale(1);
  transition: all cubic-bezier(0, 0, 0.2, 1) 300ms;
}
.modal-exit > * {
  opacity: 1;
  transform: translateY(0) scale(1);
}
.modal-exit-active > * {
  opacity: 0;
  transform: translateY(-100px) scale(0.9);
  transition: all cubic-bezier(0, 0, 0.2, 1) 300ms;
}

body .skeleton,
body .skeleton:hover {
  background-color: rgba(0, 0, 0, 0.15);
  box-shadow: none;
  cursor: initial;
  overflow: hidden;
}
body .skeleton::after {
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-name: slide;
  animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

@keyframes slide {
  0% {
    transform: translateX(-100%);
  }

  49% {
    transform: translateX(100%);
  }
  50% {
    transform: translateX(100%) rotate(180deg);
  }

  100% {
    transform: translateX(-100%) rotate(180deg);
  }
}

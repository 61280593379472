.Alert {
  align-items: center;
  border: 2px solid transparent;
  border-radius: 5px;
  display: flex;
  overflow: hidden;
  padding: 16px;
  position: relative;
}

.warning {
  background-color: var(--warning-background);
  border-color: var(--warning-border);
  color: var(--warning-text);
}
.error {
  background-color: var(--error-background);
  border-color: var(--error-border);
  color: var(--error-text);
}
.info {
  background-color: var(--info-background);
  border-color: var(--info-border);
  color: var(--info-text);
}
.success {
  background-color: var(--success-background);
  border-color: var(--success-border);
  color: var(--success-text);
}

.Close {
  cursor: pointer;
  fill: rgba(0, 0, 0, 0.3);
  height: 20px;
  padding: 2px;
  position: absolute;
  right: 2px;
  top: 2px;
  transition: all 0.15s cubic-bezier(0.4, 0, 0.6, 1);
  width: 20px;
  z-index: 1;
}
.warning .Close {
  fill: var(--warning-border);
}
.error .Close {
  fill: var(--error-border);
}
.info .Close {
  fill: var(--info-border);
}
.success .Close {
  fill: var(--success-border);
}
.Close::after {
  border-radius: 3px;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.3;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}
.warning .Close:hover::after {
  background-color: var(--warning-border);
}
.error .Close:hover::after {
  background-color: var(--error-border);
}
.info .Close:hover::after {
  background-color: var(--info-border);
}
.success .Close:hover::after {
  background-color: var(--success-border);
}

.Icon {
  fill: currentColor;
  flex-shrink: 0;
  height: 32px;
  margin-right: 16px;
  padding: 6px;
  position: relative;
  width: 32px;
  z-index: 1;
}

.Icon svg {
  height: 100%;
  opacity: 0.65;
  width: 100%;
}
.warning .Icon svg {
  fill: var(--warning-text);
}
.error .Icon svg {
  fill: var(--error-text);
}
.info .Icon svg {
  fill: var(--info-text);
}
.success .Icon svg {
  fill: var(--success-text);
}

.Icon::after {
  border-radius: 16px;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.65;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}
.warning .Icon::after {
  background-color: var(--warning-border);
  border-color: var(--warning-border);
}
.error .Icon::after {
  background-color: var(--error-border);
  border-color: var(--error-border);
}
.info .Icon::after {
  background-color: var(--info-border);
  border-color: var(--info-border);
}
.success .Icon::after {
  background-color: var(--success-border);
  border-color: var(--success-border);
}

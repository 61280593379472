.Spinner {
  display: block;
  height: 16px;
  margin: auto;
  position: relative;
  width: 96px;
}

.Dot {
  animation: Spinner 1.8s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  background: var(--card-background);
  border: 1px solid currentColor;
  border-radius: 4px;
  display: inline-block;
  height: 8px;
  position: absolute;
  top: 4px;
  width: 8px;
}
.Dot::after {
  box-shadow: 0 0 3px currentColor;
  content: "";
  height: 100%;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}
.Dot::before {
  background: currentColor;
  content: "";
  height: 100%;
  opacity: 0.1;
  position: absolute;
  width: 100%;
}
.Dot:nth-child(1) {
  animation-delay: 0s;
  color: var(--kano-green);
  left: 4px;
}
.Dot:nth-child(2) {
  animation-delay: 0.12s;
  color: var(--kano-blue);
  left: 20px;
}
.Dot:nth-child(3) {
  animation-delay: 0.24s;
  color: var(--kano-yellow);
  left: 36px;
}
.Dot:nth-child(4) {
  animation-delay: 0.36s;
  color: var(--kano-purple);
  left: 52px;
}
.Dot:nth-child(5) {
  animation-delay: 0.48s;
  color: var(--kano-grey);
  left: 68px;
}
.Dot:nth-child(6) {
  animation-delay: 0.6s;
  color: var(--kano-red);
  left: 84px;
}

@keyframes Spinner {
  0% {
    background-color: currentColor;
    transform: scale(2);
  }
  50%,
  100% {
    background-color: transparent;
    transform: scale(1);
  }
}
